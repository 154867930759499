// @flow

import 'whatwg-fetch'

const BASE_URL = process.env.GATSBY_API_URL || ''
const GENERIC_ERROR = 'Something went wrong. Please try again.'

const buildHeaders = () => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  }

  // if (authToken) {
  //   return {
  //     ...defaultHeaders,
  //     Authorization: `Bearer ${authToken}`,
  //   }
  // }

  return defaultHeaders
}

// const checkStatusAndParseJSON = response =>
//   response
//     .json()
//     .then(json => {
//       if (response.status >= 200 && response.status <= 300) {
//         return Promise.resolve(json)
//       }

//       return Promise.reject(json.message || GENERIC_ERROR)
//     })
//     .catch(message => {
//       if (response.status >= 200 && response.status <= 300) {
//         return Promise.resolve({})
//       }

//       return Promise.reject(message || GENERIC_ERROR)
//     })

// const bail = () => Promise.reject(GENERIC_ERROR)

// const post = (endpoint, data) =>
//   fetch(`${BASE_URL}/${endpoint}`, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     mode: 'cors',
//     // credentials: 'include',
//     headers: {
//       ...buildHeaders(),
//     },
//   })
//     .then(checkStatusAndParseJSON)
// .catch(bail)

const post = (endpoint, data) =>
  new Promise<null>(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/${endpoint}`, {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
          ...buildHeaders(),
        },
      })

      const json = await response.json()

      if (response.status >= 200 && response.status <= 300) {
        return resolve(json)
      }

      return reject(json.message || GENERIC_ERROR)
    } catch (e) {
      reject(e)
    }

    return null
  })

const newsletterSignUp = ({ email }: { email: string }) => post('newsletter-sign-up', { email })
const sendMessage = (formData: {
  firstName: string,
  lastName: string,
  company: string,
  email: string,
  message: string,
  subscribeToNewsletter: boolean,
}) => post('send-message', formData)

export default {
  newsletterSignUp,
  sendMessage,
}
