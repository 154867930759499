import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'
import { ButtonContainer } from '../Button/style'

const getBaseStyleFromKind = kind => {
  switch (kind) {
    case 'grey':
      return css`
        background: ${Colors.util.fade({ color: Colors.grey3, amount: 0.9 })};
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        height: 56px;
        align-items: center;
        box-sizing: border-box;

        &:focus-within {
          background: ${Colors.util.fade({ color: Colors.grey3, amount: 0.85 })};
        }

        input,
        textarea {
          color: ${Colors.grey1};

          ::placeholder {
            color: ${Colors.grey4};
            opacity: 0.5;
          }
        }
      `

    default:
      return css`
        border: 2px solid ${Colors.grey6};
        border-radius: 6px;
        background: ${Colors.white};

        &:focus-within {
          background: ${Colors.util.fade({ color: Colors.grey4, amount: 0.95 })};
        }

        input,
        textarea {
          color: ${Colors.grey1};

          ::placeholder {
            color: ${Colors.grey4};
            opacity: 0.5;
          }
        }
      `
  }
}

// TODO: switch on kind
const getErrorStyleFromProps = () =>
  css`
    border: 2px solid ${Colors.error};

    ${ButtonContainer} {
      height: 100%;
    }
  `

// TODO: switch on kind
const getDisabledStyleFromKind = () => css`
  color: ${Colors.util.fade({ color: Colors.grey4, amount: 0.4 })};
  border: 2px solid ${Colors.util.fade({ color: Colors.grey6, amount: 0.5 })};
  background: ${Colors.util.fade({ color: Colors.grey6, amount: 0.5 })};
`

const getInputContainerStyle = props => {
  const { kind, hasErrors, disabled, hasButton } = props

  const baseStyle = css`
    ${getBaseStyleFromKind(kind)};

    ${ButtonContainer} {
      border-top-left-radius: ${hasButton ? 0 : 'inherit'};
      border-bottom-left-radius: ${hasButton ? 0 : 'inherit'};
    }
  `

  if (hasErrors) {
    return css`
      ${baseStyle};
      ${getErrorStyleFromProps()};
    `
  }

  if (disabled) {
    return css`
      ${baseStyle};
      ${getDisabledStyleFromKind()};
    `
  }

  return baseStyle
}

const getHoverStateFromProps = ({ kind, hasErrors, disabled }) => {
  switch (kind) {
    case 'grey': {
      return css`
        background: ${Colors.util.fade({ color: Colors.grey3, amount: 0.85 })};
      `
    }

    default: {
      const baseStyle = css`
        background: ${Colors.util.fade({ color: Colors.grey4, amount: 0.95 })};
      `

      if (hasErrors) {
        return css`
          ${baseStyle};
          ${getErrorStyleFromProps()};
        `
      }

      if (disabled) {
        return css`
          ${baseStyle};
          ${getDisabledStyleFromKind()};
        `
      }

      return baseStyle
    }
  }
}

export const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: space-between; */
  ${getInputContainerStyle};
`

export const Container = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:hover {
    ${InputContainer} {
      ${getHoverStateFromProps};
    }
  }
`

export const Label = styled.div`
  ${Fonts.Body2};
  user-select: none;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: baseline;
  padding-left: 8px;
`

export const Caption = styled.div`
  ${Fonts.Caption};
  margin-left: 4px;
  opacity: 0.5;
`

const getIconStyleFromKind = kind => {
  switch (kind) {
    case 'grey':
      return css`
        color: ${Colors.grey4};
      `

    default:
      return css`
        color: ${Colors.grey4};
      `
  }
}

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 16px;
  margin-right: 8px;
  ${getIconStyleFromKind};
`

export const Error = styled.div`
  ${Fonts.Caption};
  color: ${Colors.error};
  padding-top: 4px;
  margin-left: 4px;
`

// const textInputElementStatesStyle = props => {
//   const defaultStyle = css`
//     border: ${props['data-on-dark'] ? `2px solid ${Colors.grey6}` : `2px solid ${Colors.grey6}`};
//     background: ${Colors.white};
//     color: ${props['data-on-dark'] ? Colors.offWhite : Colors.grey1};

//     ::placeholder {
//       color: ${props['data-on-dark'] ? Colors.grey4 : Colors.grey4};
//       opacity: 0.5;
//     }

//     &:hover {
//       background: ${Colors.util.fade({ color: Colors.grey4, amount: 0.95 })};
//     }

//     &:focus {
//       background: ${Colors.util.fade({ color: Colors.grey4, amount: 0.95 })};
//     }
//   `

//   if (props['data-has-errors']) {
//     return css`
//       ${defaultStyle};
//       border: 2px solid ${Colors.error};
//     `
//   }

//   if (props.disabled) {
//     return css`
//       ${defaultStyle};
//       color: ${props['data-on-dark']
//         ? Colors.util.fade({ color: Colors.offWhite, amount: 0.5 })
//         : Colors.util.fade({ color: Colors.grey4, amount: 0.4 })};
//       border: ${props['data-on-dark']
//         ? `2px solid ${Colors.util.fade({ color: Colors.grey6, amount: 0.25 })}`
//         : `2px solid ${Colors.util.fade({ color: Colors.grey6, amount: 0.5 })}`};
//       background: ${props['data-on-dark']
//         ? Colors.util.fade({ color: Colors.black, amount: 0.2 })
//         : Colors.util.fade({ color: Colors.grey6, amount: 0.5 })};

//       &:hover {
//         background: ${props['data-on-dark']
//           ? Colors.util.fade({ color: Colors.black, amount: 0.2 })
//           : Colors.util.fade({ color: Colors.grey6, amount: 0.5 })};
//       }
//     `
//   }

//   return defaultStyle
// }

const DefaultInputStyle = css`
  ${Fonts.Body2};
  box-sizing: border-box;
  padding: 16px 8px;
  margin: 0;
  height: 100%;
  flex: 1;
  box-shadow: none;
  appearance: none;
  outline: none;
  background: transparent;
  border: none;
`

export const TextInputElement = styled.input`
  ${DefaultInputStyle};
`

export const TextareaElement = styled.textarea`
  ${DefaultInputStyle};
`
