// @flow

import * as React from 'react'
import Icon from '../Icon'
import { Container, LabelText, IconContainer } from './style'

type Props = {
  onChange: Function,
  checked: boolean,
  label?: string,
  radio?: boolean,
  disabled?: boolean,
}

class Checkbox extends React.Component<Props, *> {
  static defaultProps = {
    label: '',
    radio: false,
    disabled: false,
  }

  renderLabel = () => {
    const { label = '' } = this.props

    if (!label.length) {
      return null
    }

    return <LabelText>{label}</LabelText>
  }

  renderIcon = () => {
    const { checked, disabled } = this.props

    if (checked) {
      return (
        <IconContainer disabled={disabled}>
          <Icon type="checkbox-checked" />
        </IconContainer>
      )
    }

    return (
      <IconContainer>
        <Icon type="checkbox" />
      </IconContainer>
    )
  }

  handleClick = () => {
    const { radio, checked, disabled, onChange } = this.props

    if ((radio && checked) || disabled) {
      return
    }

    onChange(!checked)
  }

  render() {
    const { disabled } = this.props

    return (
      <Container onClick={this.handleClick} disabled={disabled}>
        {this.renderIcon()}
        {this.renderLabel()}
      </Container>
    )
  }
}

export default Checkbox
