// @flow

import styled from '@emotion/styled'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'
import { NOTIFICATION_TYPES } from '../../constants'

export const Container = styled.div`
  ${Fonts.Body};
  padding: 8px;
  background: ${props =>
    props.type === NOTIFICATION_TYPES.ERROR ? Colors.error : Colors.secondary};
  color: ${Colors.white};
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

export const IconContainer = styled.div`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`
