// @flow

import styled from '@emotion/styled'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'

export const Container = styled.label`
  cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding-right: 4px;
`

export const LabelText = styled.span`
  ${Fonts.Body2};
  margin-left: 16px;
  display: block;
`

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  color: ${Colors.grey6};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`
