// @flow

import React from 'react'
import validate from 'validate.js'
import Analytics from '../../utils/analytics'
import Button from '../Button'
import TextInput from '../TextInput'
import Checkbox from '../Checkbox'
import Notification from '../Notification'
import { Container, Row } from './style'
import { NOTIFICATION_TYPES } from '../../constants'
import API from '../../services/api'

type Props = {
  submitButtonLabel: string,
}

type State = {
  isSubmitting: boolean,
  formData: {|
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    message: string,
    subscribeToNewsletter: boolean,
  |},
  validationErrors: {
    firstName?: Array<string>,
    lastName?: Array<string>,
    email?: Array<string>,
    message?: Array<string>,
  },
  error: null | string,
  success: null | string,
}

const defaultFormData = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  message: '',
  subscribeToNewsletter: false,
}

class ContactForm extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
    formData: defaultFormData,
    validationErrors: {},
    error: null,
    success: null,
  }

  validationConstraints = {
    firstName: {
      presence: { allowEmpty: false },
    },
    lastName: {
      presence: { allowEmpty: false },
    },
    email: {
      presence: { allowEmpty: false },
    },
    message: {
      presence: { allowEmpty: false },
    },
  }

  validateFields = () => {
    const { formData } = this.state
    const validationErrors = validate(formData, this.validationConstraints)

    if (validationErrors) {
      Analytics.event({
        category: 'Contact Form',
        action: 'Validation Errors',
      })

      this.setState({
        validationErrors,
        error: 'Please correct the items in red and try again.',
        success: null,
      })
      return false
    }

    this.setState({ validationErrors: {}, success: null, error: null })
    return true
  }

  handleFirstNameChange = (firstName: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      firstName,
    }

    this.setState({ formData: updatedFormData })
  }

  handleLastNameChange = (lastName: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      lastName,
    }

    this.setState({ formData: updatedFormData })
  }

  handleCompanyChange = (company: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      company,
    }

    this.setState({ formData: updatedFormData })
  }

  handleEmailChange = (email: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      email,
    }

    this.setState({ formData: updatedFormData })
  }

  handleMessageChange = (message: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      message,
    }

    this.setState({ formData: updatedFormData })
  }

  handleSubscribeToNewsletterChange = (value: boolean) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      subscribeToNewsletter: value,
    }

    this.setState({ formData: updatedFormData })
  }

  handleSubmit = (e?: { preventDefault: Function }) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const { formData } = this.state

    Analytics.event({
      category: 'Contact Form',
      action: 'Submit',
      label: `Subscribe to newsletter: ${formData.subscribeToNewsletter ? 'Yes' : 'No'}`,
    })

    if (this.validateFields()) {
      this.setState({ isSubmitting: true, error: null, success: null })

      API.sendMessage(formData)
        .then(() => {
          this.setState({
            isSubmitting: false,
            success: "Message received! We'll be in touch shortly.",
            formData: defaultFormData,
          })

          Analytics.event({
            category: 'Contact Form',
            action: 'Success',
            label: `Subscribe to newsletter: ${formData.subscribeToNewsletter ? 'Yes' : 'No'}`,
          })
        })
        .catch(error => {
          this.setState({ isSubmitting: false, success: null, error })

          Analytics.event({
            category: 'Contact Form',
            action: 'Error',
            label: `Subscribe to newsletter: ${
              formData.subscribeToNewsletter ? 'Yes' : 'No'
            }. Error: ${error}`,
          })
        })
    }
  }

  render() {
    const { submitButtonLabel } = this.props
    const { isSubmitting, formData, validationErrors, error, success } = this.state

    return (
      <Container onSubmit={this.handleSubmit}>
        <Row>
          <TextInput
            label="First name"
            value={formData.firstName}
            disabled={isSubmitting}
            onChange={this.handleFirstNameChange}
            errors={validationErrors.firstName}
          />
          <TextInput
            label="Last name"
            value={formData.lastName}
            disabled={isSubmitting}
            onChange={this.handleLastNameChange}
            errors={validationErrors.lastName}
          />
        </Row>
        <Row>
          <TextInput
            label="Your Company"
            caption="optional"
            value={formData.company}
            disabled={isSubmitting}
            onChange={this.handleCompanyChange}
          />
          <TextInput
            label="Email"
            value={formData.email}
            disabled={isSubmitting}
            onChange={this.handleEmailChange}
            errors={validationErrors.email}
          />
        </Row>
        <Row>
          <TextInput
            type="area"
            rows={4}
            label="Message"
            value={formData.message}
            disabled={isSubmitting}
            onChange={this.handleMessageChange}
            errors={validationErrors.message}
          />
        </Row>
        <Row>
          <Checkbox
            label="Subscribe to our newsletter"
            checked={formData.subscribeToNewsletter}
            disabled={isSubmitting}
            onChange={this.handleSubscribeToNewsletterChange}
          />
        </Row>
        <Row>
          {error ? <Notification type={NOTIFICATION_TYPES.ERROR}>{error}</Notification> : null}
          {success ? (
            <Notification type={NOTIFICATION_TYPES.SUCCESS}>{success}</Notification>
          ) : null}
        </Row>
        <Row>
          <Button
            loading={isSubmitting}
            type="submit"
            label={submitButtonLabel}
            style={{ flex: 1 }}
          />
        </Row>
      </Container>
    )
  }
}

export default ContactForm
